import React from 'react';
import { URL_APP_FRASES_ANDROID, URL_APP_FRASES_IOS } from '../../../../constants';
import { Button, Main, Row, RowAndSpaceBetween, SubTitleSection, Text, TextButton, TitleMain } from '../../../atomics';
import Section from '../../../Section';
import Page from '../../Page';

const primaryColor = '#F1C40F'
function Frases() {
  const sendEvent = (event)=>{
    if(window){
      if(window.fbq) window.fbq('track', event);             
      if(window.gtag)window.gtag("event", event);
    }
  }
  return (
    <Page primaryColor={primaryColor} title="Flynow - Frases" logo={require(`../../../../assets/images/app/frases/logo.png`)} imageUrl={require('../../../../assets/images/app/frases/app-frases.png')} description="Encontre motivação diária a um clique. Tenha um empurrãozinho diário para começar o dia inspirado com frases sobre motivação, sabedoria, reflexão e muito mais! Aplicativo Flynow - Frases de Motivação e Sabedoria" pageId="app_frases">
      <Main>
        <div>
          <TitleMain>
            Encontre motivação diária a um clique
          </TitleMain>
          <Text style={{marginTop:10}}>
          Tenha um empurrãozinho diário para começar o dia inspirado com frases sobre motivação, sabedoria, reflexão e muito mais!
          </Text>

          <Row style={{marginTop:30}}>
            <Button href={URL_APP_FRASES_ANDROID } onClick={()=> sendEvent('download_app_phrases_android')} style={{backgroundColor: primaryColor}}>
              <img src={require('../../../../assets/images/playstore.png')} alt="playstore" className="icon-store"/>
              <TextButton> Baixar na Google Play</TextButton>
            </Button>
            <Button 
            style={{backgroundColor: primaryColor,marginLeft:10}} 
              href={URL_APP_FRASES_IOS}
              onClick={()=> sendEvent('download_app_phrases_ios')}
            >
              <img src={require('../../../../assets/images/applestore.png')} alt="applestore" className="icon-store"/>
              <TextButton> Baixar na App Store</TextButton>
            </Button>
          </Row>
        </div>
        <img src={require('../../../../assets/images/app/frases/app-frases.png')} alt="aplicativo" className="img-main"/>
      </Main>

      <Section title={"Por que usar o nosso app?"} id="funcionalidades" color={primaryColor}>
        <div>
            <RowAndSpaceBetween>
              <div style={{marginTop:40,minWidth:330}}>
                <SubTitleSection>
                Receba notificações em horários desejados
                </SubTitleSection>
                <Text style={{marginTop:30}}>
                Com o App Flynow - Frases de Motivação & Sabedoria, você pode definir quais horários do dia você deseja receber uma notificação com uma frase para te motivar. Super prático, não é mesmo?
                </Text>
              </div>
              <img src={require('../../../../assets/images/app/frases/definir_horarios.png')} alt="aplicativo" className="img-func"/>
            </RowAndSpaceBetween>
          </div>

          <div style={{marginTop:30}}>
            <RowAndSpaceBetween reverseWrap>
              <img src={require('../../../../assets/images/app/frases/frases.png')} alt="aplicativo" className="img-func"/>
              <div style={{marginTop:40,minWidth:330}}>
                <SubTitleSection>
                Compartilhe frases como imagem ou texto
                </SubTitleSection>
                <Text style={{marginTop:30}}>
                Deseja compartilhar alguma frase? Isso não é um problema! No app, você pode compartilhar suas frases como imagens ou textos no Instagram, WhatsApp e até mesmo salvar na sua galeria.
                </Text>
              </div>
            </RowAndSpaceBetween>
          </div>

          <div>
            <RowAndSpaceBetween>
              <div style={{marginTop:40,minWidth:330}}>
                <SubTitleSection>
                Escolha frases por autores e categorias específicas
                </SubTitleSection>
                <Text style={{marginTop:30}}>
                Algum autor ou categoria te agrada mais? Se sim, também podemos te ajudar! No app, você encontra 15 autores e 9 categorias diferentes para escolher qual tipo de frase deseja visualizar. 
                </Text>
              </div>
              <img src={require('../../../../assets/images/app/frases/home.png')} alt="aplicativo" className="img-func"/>
            </RowAndSpaceBetween>
          </div>
          
          <div style={{marginTop:30}}>
            <RowAndSpaceBetween reverseWrap>
              <img src={require('../../../../assets/images/app/frases/frases_favoritas.png')} alt="aplicativo" className="img-func"/>
              <div style={{marginTop:40,minWidth:330}}>
                <SubTitleSection>
                Visualize sua lista de frases favoritas
                </SubTitleSection>
                <Text style={{marginTop:30}}>
                E se você gostar muito de alguma frase? Você pode marcá-la como favorita no app! Assim, você pode visualizar apenas sua lista de favoritos depois. Incrível, não é?
                </Text>
              </div>
            </RowAndSpaceBetween>
          </div>

          <div style={{marginTop:30}}>
            <RowAndSpaceBetween>
              <div style={{marginTop:40,minWidth:330}}>
                <SubTitleSection>
                Gerencie suas preferências
                </SubTitleSection>
                <Text style={{marginTop:30}}>
                Mudou de ideia com relação ao horário de recebimento de frases? Não se preocupe, você pode editá-lo ou removê-lo. Além disso, você pode escolher o tema de sua preferência (Dark/Light) e até enviar sua sugestão de frase ou de melhoria para o nosso app!
                </Text>
              </div>
              <img src={require('../../../../assets/images/app/frases/frase_por_categoria.png')} alt="aplicativo" className="img-func"/>
            </RowAndSpaceBetween>
          </div>
      </Section>
  </Page>
  )
}

export default Frases;